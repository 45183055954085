//---------------------------------Base URL-----------------------------------
//DEV
//export const SHOTCLASS_ROOT_API_URL = "https://dev1services.shotclasses.com";
//QA
// export const SHOTCLASS_ROOT_API_URL = "https://qa1services.shotclasses.com";
//Prod-EU
// export const SHOTCLASS_ROOT_API_URL = "https://services1.shotclasses.com";
//NEW - Prod-EU
//export const SHOTCLASS_ROOT_API_URL = "https://prod-apis.azurewebsites.net";
//Prod-US
//export const SHOTCLASS_ROOT_API_URL = "https://services2.shotclasses.com";
//LTIM-Prod
export const SHOTCLASS_ROOT_API_URL = "https://lighthouseservices.ltimindtree.com";
// NEW-QA
//export const SHOTCLASS_ROOT_API_URL = "https://qa-apis.azurewebsites.net";
//Local
//export const SHOTCLASS_ROOT_API_URL = "http://localhost:65422";
// NEW-QA
//export const SHOTCLASS_ROOT_API_URL = "https://qa-apis.azurewebsites.net";


export const SHOTCLASS_API_URL = SHOTCLASS_ROOT_API_URL + "/api/v3";
//---------------------------------End Base URL-----------------------------------

//------------------------Adal Config region------------------------------------
export const O365_RESOURCE = "https://sceur.sharepoint.com";

//Dev
// export const CLIENTID = "1096bef4-73bf-4f96-ae41-e9b9017ea3bc";
// export const LOGIN_RESOURCE = "http://shotclassesdev.onmicrosoft.com/services";

//QA
// export const CLIENTID = "a521d18b-9da3-4332-9b66-43e2705efef1";
// export const LOGIN_RESOURCE = "http://shotclassesdev.onmicrosoft.com/services";

//Europe Prod
// export const CLIENTID = "d0b98fe4-5791-4ce3-83e1-b336f77af83a";
// export const LOGIN_RESOURCE = "https://prod1ad.onmicrosoft.com/api";


//NEW Europe Prod
//export const CLIENTID = "d0b98fe4-5791-4ce3-83e1-b336f77af83a";
//export const LOGIN_RESOURCE = "https://prod1ad.onmicrosoft.com/api";

//US Prod
// export const CLIENTID = "a5b00bb2-e6f8-4736-b76a-216f93f83ebf";
// export const LOGIN_RESOURCE = "https://prod1usad.onmicrosoft.com/api";

//LTIM-Prod
export const CLIENTID = "95d25ba7-81b1-40cb-9b1f-fd97b3ddb8b6";
export const LOGIN_RESOURCE = "api://ltimindtree.com/746b76d5-c030-4ce1-b06a-7624e8f138d1";

//QA-New
// export const CLIENTID = "0a9e58ac-81c2-402a-a743-a5038ba2d1b2";
// export const LOGIN_RESOURCE = "api://shotclassesentraid.onmicrosoft.com/1847b815-1ad2-407c-b7f6-a3a71fa5a027";

// export const SASToken="?ajvrffoif";
//-------------------------End adal Config region----------------------------

//-------------------------Environment name----------------------------------
//Dev Service
//export const ENV_Name = "DEV";
//QA Service
//export const ENV_Name = "QA";
//PROD Service
//export const ENV_Name = "PROD";
export const ENV_Name = "LTIM"
//-------------------------End Environment name-------------------------------

//-------------------------Mediakind License Keys-----------------------------
export const MKPLAYER_LICENSE_KEY = "d0167b1c-9767-4287-9ddc-e0fa09d31e02";
//-------------------------End Mediakind License Keys-------------------------

//get user details using Graph API
export const SIGNALR_NEGOTIATE_API_URL =
    "https://devshortclassfuntionapp.azurewebsites.net";
export const GRAPH_ENDPOINT_API_URL = "https://graph.microsoft.com/v1.0/me";

//get tenant details
export const PRE_LOGIN_API_URL =
    SHOTCLASS_API_URL + "/Tenants/details?TenantName=";

//login
export const LOGIN_API_URL = SHOTCLASS_API_URL + "/Logins/Token";
//aad login admin consent
export const AAD_ADMIN_CONSENT_API_URL =
    SHOTCLASS_ROOT_API_URL + "/api/v1/AAD/PostAADAdminConsent";
//logout
export const LOGOUT_API_URL = SHOTCLASS_API_URL + "/Users/";

//check if user exists/active or not
export const ISUSER_EXISTS_API_URL = SHOTCLASS_API_URL + "/Login/IsUserIdExist";
export const ISUSER_ACTIVE_API_URL = SHOTCLASS_API_URL + "/Login/IsUserActive";
//forgot password -setnt mail
export const SENTMAIL_FORGOTPASSWORD_API_URL =
    SHOTCLASS_API_URL + "/Login/ForgotPassword";
//check if password reset is required or not
export const ISPASSWORD_RESET_REQUIRED_API_URL =
    SHOTCLASS_API_URL + "/Login/PasswordResetRequired";

//home apis

//get all languages
export const GET_ALL_LANGUAGES_API_URL =
    SHOTCLASS_API_URL + "/MasterLanguage/GetAllVariantLanguages";
//get notifications
export const GET_NOTIFICATIONS_API_URL =
    SHOTCLASS_API_URL + "/Notifications/NotificationsHistory";

//get leaderboard points of user
export const GET_LEADERBOARDDATA_API_URL =
    SHOTCLASS_API_URL + "/me/games/userLeaderboard";

//get leaderboard details
export const GET_LEADERBOARD_DETAILS =
    SHOTCLASS_API_URL + "/me/games/userLeaderboard?gameCode=";

//get last login time
export const GET_LAST_LOGIN_TIME_API_URL =
    SHOTCLASS_API_URL + "/me/lastLoginTime";
//get user profile
export const GET_USER_PROFILE_API_URL = SHOTCLASS_API_URL + "/me/profile";

//home classes and series apis
export const GET_ASSIGNED_CLASSES_API_URL =
    SHOTCLASS_API_URL + "/me/ShotClasses/assigned/none?pageNum=";

export const GET_COMPLETED_CLASSES_API_URL =
    SHOTCLASS_API_URL + "/me/ShotClasses/complete/none?pageNum=";

export const GET_TRENDING_CLASSES_API_URL =
    SHOTCLASS_API_URL + "/ShotClasses/trending/none?Locale=";

export const GET_ASSIGNED_SERIES_API_URL =
    SHOTCLASS_API_URL + "/me/programs/assigned?pageNum=";

export const GET_COMPLETED_SERIES_API_URL =
    SHOTCLASS_API_URL + "/me/programs/completed?pageNum=";

export const GET_TRENDING_SERIES_API_URL =
    SHOTCLASS_API_URL + "/me/programs/trending";
//get privacy policy for a user
export const GET_USER_PRIVACY_POLICY_URL =
    SHOTCLASS_API_URL + "/PrivacyPolicies/info";
//post privacy policy for a user
export const POST_USER_PRIVACY_POLICY_URL =
    SHOTCLASS_API_URL + "/PrivacyPolicies";

//get class details by classID
export const GET_CLASS_DETAILS_API_URL = SHOTCLASS_API_URL + "/ShotClasses/";
//get user class access by classID
export const GET_USER_CLASS_ACCESS_API_URL =
    SHOTCLASS_API_URL + "/ShotClasses/IsUserHaveAccessToClass?shotClassId=";
//get queries for class
export const GET_QUERIES_CLASS_API_URL =
    SHOTCLASS_API_URL + "/me/LearnerQueries";
//post query comment
export const POST_QUERY_COMMENT_API_URL = SHOTCLASS_API_URL + "/LearnerQueries";
//Post query class
export const POST_QUERY_CLASS_API_URL = SHOTCLASS_API_URL + "/LearnerQueries";
//get class asset id
export const GET_CLASS_ASSET_ID_API_URL =
    SHOTCLASS_API_URL + "/mediaservices/shotclass/tokens?assetId=";
//post class status/progress in class
export const UPDATE_CLASS_PROGRESS_STATUS_API_URL =
    SHOTCLASS_ROOT_API_URL + "/api/v3/me/shotclasses/status?classId=";
//post video played duration in class
export const UPDATE_VIDEO_PLAYED_DURATION_API_URL =
    SHOTCLASS_ROOT_API_URL +
    "/api/v1/ShotClass/UpdateVideoPlayedDuration?classId=";
//post assessment score
export const POST_ASSESSMENT_SCORE_API_URL =
    SHOTCLASS_API_URL + "/ShotClasses/";
//get next class
export const GET_NEXT_CLASS_API_URL =
    SHOTCLASS_API_URL + "/me/shotclasses/next/none";
//get next class in series
export const GET_NEXT_CLASS_IN_SERIES_API_URL =
    SHOTCLASS_API_URL + "/me/shotclasses/nextClassInSeriesV4?programID=";
//get related classes
export const GET_RELATED_CLASSES_API_URL =
    SHOTCLASS_API_URL + "/shotclasses/related?locale=";
//post ratings for class
export const POST_RATING_CLASS_API_URL = SHOTCLASS_API_URL + "/me/shotclasses/";
//post survey
export const POST_SURVEY_API_URL =
    SHOTCLASS_API_URL + "/ShotClasses/postUserSurvey";

//get scrom version
export const GET_SCORM_VERSION_API_URL = SHOTCLASS_API_URL + "/scorms/version/";
//get scrom bookmark
export const GET_SCORM_BOOKMARK_API_URL =
    SHOTCLASS_API_URL + "/scorms/bookmark/";
//update scrom bookmark
export const UPDATE_SCORM_BOOKMARK_API_URL =
    SHOTCLASS_API_URL + "/scorms/updatebookmark";

//get classes details by seriesID
export const GET_CLASSES_BY_SERIESID_API_URL =
    SHOTCLASS_API_URL + "/me/ShotClasses/getClassesByProgramID?ProgramID=";

// add/remove favourite class
export const ADD_REMOVE_FAVORITE_CLASS_API_URL =
    SHOTCLASS_API_URL + "/shotclasses/";
// add/remove favourite series
export const ADD_REMOVE_FAVORITE_SERIES_API_URL =
    SHOTCLASS_API_URL + "/programs/";

//get favorites classes and series
export const GET_FAVORITE_CLASSES_SERIES_API_URL =
    SHOTCLASS_API_URL + "/me/courses/favourite";

//get published topics
export const GET_ALL_PUBLISHED_TOPICS_API_URL =
    SHOTCLASS_API_URL + "/topics/grouptopics/published?pageNo=";
//get published topics
export const GET_TOPIC_DETAILS_API_URL = SHOTCLASS_API_URL + "/topics/";

//get Learner queries
export const GET_GENERAL_QUERIES_API_URL =
    SHOTCLASS_API_URL + "/me/LearnerQueries";
//Put Learner queries
export const PUT_GENERAL_QUERIES_API_URL =
    SHOTCLASS_API_URL + "/LearnerQueries";
//Post Learner queries
export const POST_GENERAL_QUERIES_API_URL =
    SHOTCLASS_API_URL + "/LearnerQueries";

//get Learner Forum Topics
export const GET_FORUM_TOPICS_API_URL = SHOTCLASS_API_URL + "/me/forumtopics";

//get contribute count for a user
export const GET_CONTRIBUTE_CLASSES_SUBMITTED =
    SHOTCLASS_API_URL + "/ShotClassSourcings/submitted";
//post contribute  for a user
export const POST_CONTRIBUTE_CLASSES =
    SHOTCLASS_API_URL + "/ShotClassSourcings";
// Get Contribute through youtube search
export const GET_CONTRIBUTE_YOUTUBE_SEARCH =
    SHOTCLASS_API_URL + "/Videos/youtubevideos?searchTerm=";
// Get Contribute through youtube search count
export const GET_CONTRIBUTE_YOUTUBE_COUNT =
    SHOTCLASS_API_URL + "/Videos/youtubevideoscount?searchTerm=";
//validate quiz passcode
export const VALIDATE_PASSCODE_API_URL =
    SHOTCLASS_API_URL + "/LiveQuizEvents/ValidateLiveEventPasscode";
//https://dev1services.shotclasses.com/api/v3/LiveQuizEvents/GetLiveQuizUserLeaderBoard?passCode=romeo
export const GET_LIVE_QUIZ_USER_LEADER_BOARD =
    SHOTCLASS_API_URL + "/LiveQuizEvents/GetLiveQuizUserLeaderBoard?passCode=";
export const PASSWORD_RESET_API_URL = SHOTCLASS_API_URL + "/Users/Password";
// get notification api
export const GET_BELL_NOTIFICATIONS_API_URL =
    SHOTCLASS_API_URL + "/Notifications/NotificationsHistory";
export const FETCH_VIRTUAL_QUIZ_API_URL =
    SHOTCLASS_API_URL + "/ShotClasses/GetClassDetailsForVirtualOrLiveEvent";
export const PUT_EDIT_PROFILE = SHOTCLASS_API_URL + "/Users/userID";
export const GET_VIRTUAL_QUIZ_LEADERBOARD =
    SHOTCLASS_API_URL + "/LiveQuizEvents/GetLiveQuizLeaderBoard";
export const GET_VIRTUAL_QUIZ_QNA =
    SHOTCLASS_API_URL + "/ShotClasses/GetClassDetailsForVirtualOrLiveEvent";
export const GET_VIRTUAL_QUIZ_CORRECT_ANSWER_REPORT =
    SHOTCLASS_API_URL + "/LiveQuizEvents/GetCorrectAnswerReport";
export const GET_LIVE_QUIZ_BEGIN_END_LIVE_QUIZ_QUESTION =
    SHOTCLASS_API_URL + "/LiveQuizEvents/BeginEndLiveQuizQuestion";
export const GET_LIVE_QUIZ_USER_CHOICE_COUNT =
    SHOTCLASS_API_URL + "/LiveQuizEvents/GetUserChoiceCountLiveQuiz";
export const POST_LIVE_QUIZ_ASSESSMENT_SCORE_API_URL =
    SHOTCLASS_API_URL + "/LiveQuizEvents/PostAssesScoreForLiveQuizForShotclasses";

//notification enabled settings
export const GET_NOTIFICATION_SETTINGS_API_URL =
    SHOTCLASS_API_URL + "/me/notification/Enabled";

//notification off/disable settings
export const POST_NOTIFICATION_SETTINGS_API_URL = SHOTCLASS_API_URL + "/me/";

//contact us
export const POST_CONTACT_US_API_URL =
    SHOTCLASS_API_URL + "/Notifications/postComplaintWeb";

//cotactus history
export const GET_CONTACT_US_API_URL =
    SHOTCLASS_API_URL + "/Notifications/ContactUsHistory";

//reports
export const REPORT_GET_CONSOLIDATEDREPORT =
    SHOTCLASS_API_URL + "/me/reports/learner/consolidated";
export const REPORT_GET_SKILLEDCLASSES =
    SHOTCLASS_API_URL + "/me/reports/learner/skills/";
export const REPORT_GET_MYSKILLS =
    SHOTCLASS_API_URL + "/me/reports/learner/skills";
export const REPORT_GET_MYCERTIFICATES =
    SHOTCLASS_API_URL + "/me/reports/learner/certificates";
export const REPORT_GET_USERBADGES =
    SHOTCLASS_API_URL + "/me/reports/learner/badges";
